import languages from './languages.json'

export enum Elocales {
  ar = "ar",
  en = "en",
  fr = "fr",
  de = "de",
  ru = "ru",
  es = "es",
  tr = "tr",
  el = "el",
}