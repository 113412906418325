import React, { useContext } from "react";
import ThemeContext from "../../contexts/theme";

import styles from "./styles.module.css";

import logo from "./image/jaac-logo.svg";

interface ILogoProps {
  className?: string;
}

const Logo: React.FC<ILogoProps> = ({ className }) => {
  const { themeImageLogo } = useContext(ThemeContext);
  return (
    <img src={`${themeImageLogo ? themeImageLogo : logo}`} alt="Jaac" className={`${styles.logo} ${className}`} />
  );
}
export default Logo;
