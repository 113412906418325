import reactRender from '@bbob/react/es/render'
import reactPreset from '@bbob/preset-react/es'

const getStyleFromAttrs = attrs => {
  const keys = Object.keys(attrs);
  let obj = {};
  keys.map(key => {
    obj[key] = attrs[key];
    return key;
  });

  return obj;
}
  

const preset = reactPreset.extend((tags, options) => ({
  style: node => ({
    tag: "span",
    attrs: {
      style: getStyleFromAttrs(node.attrs)
    },
    content: node.content
  }),
  br: node => ({
    tag: "br"
  })
}));

export const renderToReact = (input: string) => input ? reactRender(input, preset()) : input;

// Sample usage:
// '[style color="red" backgroundColor="pink"]Text[/style]'
// For inline breaks = [/br]