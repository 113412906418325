import React from "react";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";

import { renderToReact } from "../../utils/renderToReact";
import ThemeContext from "../../contexts/theme";

import { THEME_ICONS_PATH } from "../../utils/constants";

interface IProps {
  open?: boolean;
  defaultOpen?: boolean;
  closeOnDocumentClick?: boolean;
  icon?: JSX.Element;
  component?: JSX.Element;
  heading?: string;
  subheading?: string;
  noScroll?: boolean;
  scrollableTextComponent?: JSX.Element;
  cross?: boolean;
  check?: boolean;
  to?: string;
  onCrossClick?: (ev: React.MouseEvent) => void;
  onClick?: (ev: React.MouseEvent) => void;
  onClose?: any;
}

const Modal: React.FC<IProps> = ({
  open,
  defaultOpen,
  closeOnDocumentClick,
  icon,
  subheading,
  heading,
  cross,
  check,
  to,
  onClose,
  onClick,
  onCrossClick,
  component,
  noScroll = true,
  scrollableTextComponent
}) => {
  const { themeConfig } = React.useContext(ThemeContext);
  const { style = {} } = themeConfig || {};
  const { fontFamily, colorModalCheckButtonBackground, colorModalCrossButtonBackground, colorFontModalBody, colorFontModalTitle } = style;

  return (
    <Popup
      contentStyle={{
        width: 340,
        height: scrollableTextComponent ? 500 : 440,
        background: `url(${THEME_ICONS_PATH}/modal_background_image.svg)`,
        backgroundSize: "cover",
        border: "none",
        padding: 0,
        zIndex: 999,
      }}
      lockScroll
      className={scrollableTextComponent && styles.popupWithScroll}
      modal
      open={open}
      defaultOpen={defaultOpen}
      onClose={onClose}
      closeOnDocumentClick={closeOnDocumentClick}
    >
      <div className={styles.popup}>
        <div className={styles.popupHeader}>
          <>
            <button
              className={styles.cross}
              style={{
                backgroundColor: colorModalCrossButtonBackground || '',
                visibility: cross ? "visible" : "hidden"
              }}
              onClick={ev => {
                if (onCrossClick) {
                  onCrossClick(ev);
                } else {
                  onClick && onClick(ev);
                }
              }}
            >
              <img src={`${THEME_ICONS_PATH}/modal_close_icon.svg`} width={60} height={60} alt="close icon" />
            </button>

            {to ? (
              <div className={styles.icon} onClick={ev => onClick && onClick(ev)}>
                <Link to={to}>{icon}</Link>
              </div>
            ) : (
              <div className={styles.icon}>{icon}</div>
            )}

            <button
              className={styles.check}
              style={{
                backgroundColor: colorModalCheckButtonBackground || '',
                visibility: check ? "visible" : "hidden"
              }}
              onClick={ev => onClick && onClick(ev)}
            >
              <img src={`${THEME_ICONS_PATH}/modal_check_icon.svg`} width={60} height={60} alt="check icon" />
            </button>
          </>
        </div>

        {component && component}

        <div className={styles.text}>
          <p style={{ fontFamily: fontFamily || '', color: colorFontModalTitle || '' }} className={styles.heading}>{heading}</p>

          <p style={{ fontFamily: fontFamily || '', color: colorFontModalBody || '' }} className={styles.subheading}> {renderToReact(subheading || '')} </p>

          <div className={`${!noScroll && styles.scrollableText} ${noScroll && styles.noScroll}`}>{scrollableTextComponent}</div>
        </div>
      </div>
    </Popup>
  );
};

export default Modal;
