import { credentials, defaultHeader } from "./headers";

import packageJson from '../../package.json';

export const checkAppVersion = async () => {
  return fetch(
      `${
        process.env.REACT_APP_API_URL
      }/system-config/frontend/guest`,
      {
        headers: {
          ...defaultHeader
        },
        credentials: credentials
      }
    )
    .then(data => data.json())
    .then(async (data) => {
      const { value = '' } = data || {};
      const { version } = packageJson;

      return {
        isUpdated: value === version,
        appVersion: version,
        updatedVersion: value
      };
    });
} 