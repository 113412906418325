import { LogglyTracker } from 'loggly-jslogger';
import { LOGGER_TYPE } from './types';
import packageJson from '../../../package.json';

interface IProps {
  name: LOGGER_TYPE,
  error: string,
  content?: string,

  [key: string]: string | undefined
}

const logger = new LogglyTracker();

logger.push({ 'logglyKey': `${process.env.REACT_APP_LOGGLY_TOKEN}` });

export const logError = (props: IProps) => logger.push({
  timestamp: new Date().toUTCString(),
  restaurantId: localStorage.getItem('restaurantId'),
  _sessionId: localStorage.getItem('userId'),
  qrToken: localStorage.getItem('_t'),
  appVersion: packageJson.version,
  ...props
});

export const logCall = (props) => logger.push({
  timestamp: new Date().toUTCString(),
  restaurantId: localStorage.getItem('restaurantId'),
  _sessionId: localStorage.getItem('userId'),
  qrToken: localStorage.getItem('_t'),
  appVersion: packageJson.version,
  tag:  "api_call",
  ...props
});

export const logDebug = (props) => logger.push({
  timestamp: new Date().toUTCString(),
  restaurantId: localStorage.getItem('restaurantId'),
  _sessionId: localStorage.getItem('userId'),
  qrToken: localStorage.getItem('_t'),
  tag: "debug",
  appVersion: packageJson.version,
  ...props
});
