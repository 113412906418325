import React from "react";
import { translate } from "react-polyglot";

import Modal from "../Modal/Modal";

import VersionContext from "../../contexts/version";

import { reloadPage } from "../../utils/reloadPage";

import {  unregister } from "../../serviceWorker";
import { THEME_ICONS_PATH } from "../../utils/constants";

interface IProps {
  t: Function;
}

const VersionChecker: React.FC<IProps> = ({ t }) => {
  const { isUpdatedVersion, getVersion } = React.useContext(VersionContext);
  const [message, setMessage] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);

  const handleReload = () => {
    unregister(() => {
      setOpen(!open);
      reloadPage(true);
    });
  }

  const isValidVersion = (version = "") => version.match(/(\d+\.\d+\.\d+[\-\.]?[a-z0-1]*)/);

  React.useEffect(() => {
    const checkVersion = async () => {
      getVersion().then(result => {
        const { isUpdated, appVersion, updatedVersion = false } = result;
        if (!isUpdated && isValidVersion(updatedVersion) !== null) {
          setMessage(t('global_modal_version_text', { old_version: appVersion, new_version: updatedVersion }));
          setOpen(!isUpdated);
        }
      });
    }

    checkVersion();
  }, [isUpdatedVersion]);

  return (
    <Modal
      open={open && message !== ""}
      onClick={handleReload}
      onCrossClick={() => setOpen(!open)}
      check
      cross
      icon={<img src={`${THEME_ICONS_PATH}/modal_warning_image_icon.svg`} width={150} height={150} style={{ marginTop: 35 }} alt="swarning icon"/>}
      heading={t('global_modal_version_header')}
      subheading={message || ""}
    />  
  )
};

export default translate()(VersionChecker);