import React from "react";

import "./loader.css";

export const Loader = () => (
  <div className="loading">
    <div className="lds-roller">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);