import React, { lazy } from "react";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import { translate } from "react-polyglot";

import PrivateRoute from "./Components/Route/PrivateRoute";
import Modal from "./Components/Modal/Modal";
import NoMatch from "./Components/NoMatch/NoMatch";

import ModalContext from "./contexts/qrmodal";

import AuthContext from "./contexts/auth";
import { THEME_ICONS_PATH } from "./utils/constants";

const CallWaiter = lazy(() => import("./Components/CallWaiter/CallWaiter"));
const Menu = lazy(() => import("./Components/Menu/Menu"));
const QRScanCamera = lazy(() =>
	import("./Components/QRScanCamera/QRScanCamera")
);
const MenuSingleItem = lazy(() =>
	import("./Components/MenuSingleItem/MenuSingleItem")
);
const QRScan = lazy(() => import("./Components/QRScan/QRScan"));
const OrderSummary = lazy(() =>
	import("./Components/OrderSummary/OrderSummary")
);
const OrdersList = lazy(() => import("./Components/OrdersList/OrdersList"));
const OrderSummaryRO = lazy(() =>
	import("./Components/OrderSummaryRO/OrderSummaryRO")
);
const PaymentPage = lazy(() => import("./Components/PaymentComponents/PaymentPage"));
const CCPage = lazy(() => import("./Components/PaymentComponents/CCPage/CCPage"));


interface IProps {
	t: Function;
}

const Routes: React.FC<IProps> = ({ t }) => {
	const { open, setOpen } = React.useContext(ModalContext);
	const { userInfo } = React.useContext(AuthContext);

  return (
    <Router>
      {!userInfo && window.location.pathname !== '/' &&
        (
          <Modal
            open={open}
            onClick={() => setOpen(!open)}
            check
            to="/qr-scan"
            icon={<img src={`${THEME_ICONS_PATH}/modal_scan_image_icon.svg`} alt="scan icon" />}
            heading={t('qr_modal_heading')}
            subheading={t('qr_modal_text')}
          />
        )
      }
      <Switch>
        <Route path="/" exact component={QRScan} />
        <PrivateRoute path="/menu" exact component={Menu} />
        <PrivateRoute path="/menu/:id" component={MenuSingleItem} />
        <PrivateRoute path="/order/summary" exact component={OrderSummary} />
        <PrivateRoute path="/orders/:id" exact component={OrderSummaryRO} />
        <PrivateRoute path="/orders" exact component={OrdersList} />
        <PrivateRoute path="/call-waiter" exact component={CallWaiter} />
				<PrivateRoute path="/payment-info" exact component={PaymentPage} />
				<PrivateRoute path="/payment/cc" exact component={CCPage} />
        <Route path="/qr-scan" exact component={QRScanCamera} />
        <Route path='/.well-known/apple-developer-merchantid-domain-association' component={() => {
            window.location.href = 'https://s3.eu-central-1.amazonaws.com/guest.dev.jaac.app/.well-known/apple-developer-merchantid-domain-association';
            return null;
        }}/>
        <Route component={NoMatch} />
      </Switch>
    </Router>
  );
};

export default translate()(Routes);
