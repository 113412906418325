import { createContext } from "react";

type NullableString = string | '' | undefined;

interface ThemeConfigStyle {
  colorQRBackgorund?: NullableString;
  colorMenuBackground?: NullableString;
  colorGalleryBackground?: NullableString;
  colorOrderListBackground?: NullableString;
  colorOrderSummaryBackground?: NullableString;
  colorCallWaiterBackground?: NullableString;

  colorNavigationBackground?: NullableString;

  fontFamily?: NullableString;
  colorFontModalTitle?: NullableString;
  colorFontModalBody?: NullableString;
  colorFontParagraph?: NullableString;
  colorFontHeaderText?: NullableString;
  colorFontCurrency?: NullableString;
  colorFontSlideshowTracker?: NullableString;
  colorFontLink?: NullableString;
  colorFontMenuItemCount?: NullableString;
  colorFontMenuItemTitle?: NullableString;

  colorHighlightedButton?: NullableString;
  colorHighlightedButtonText?: NullableString;
  colorInactiveButton?: NullableString;
  colorInactiveButtonText?: NullableString;
  colorFontMenuCategoryInactiveItem?: NullableString;
  colorActiveIconButton?: NullableString;
  colorActiveIconButtonText?: NullableString;
  colorMenuCategoryBackground?: NullableString;
  colorModalCheckButtonBackground?: NullableString;
  colorModalCrossButtonBackground?: NullableString;
  colorInactiveIconButtonText?: NullableString;
  colorDisabledIconButtonText?: NullableString;
  colorHighlightedArrowButton?: NullableString;
  colorHighlightedArrowButtonText?: NullableString;
}

interface ThemeConfig {
  style?: ThemeConfigStyle;
  urlAboutUs?: NullableString;
  urlContactUs?: NullableString;
}

interface ThemeContextProps {
  themeConfig: ThemeConfig;
  themeImageLogo?: any;
  themeImagePlaceholder?: any;
}

const ThemeContext = createContext<ThemeContextProps>({ themeConfig: { style: { } } });

export default ThemeContext;
