const orderReducer = (state, action) => {
  switch (action.type) {
    case "SET_ORDER_DATA":
      return action.payload;
    case "ADD_ORDER_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default orderReducer;
