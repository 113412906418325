import React from "react";
import { Link } from "react-router-dom";

import Logo from "../Logo/Logo";

import styles from "./styles.module.css";

const NoMatch = ({ location }) => (
  <div>
    <Logo className={styles.logo} />
    <h2>
      No match for <code>{location.pathname}</code>
    </h2>
    <h3>
      go back to <Link to="/">home</Link>{" "}
    </h3>
  </div>
);

export default NoMatch;
