const hostName = window.location.hostname;

export enum PERMISSIONS {
    ADD_TO_ORDER = 'add_to_order',
    GET_MY_ORDERS = 'get_my_orders',
    CALL_WAITER = 'call_waiter'
}

export enum PATHS {
    CUSTOM_WAITER_ICONS = 'images/icons/custom_waiter/'
}

export enum ORDER_TYPES {
    IN_HOUSE = 'in_house'
}

export enum PAYMENT_METHODS {
    CASH = 'cash',
    CARD = 'card',
    PAYPAL = 'paypal',
    APPLE_PAY = 'apple_pay',
}

export enum PAYMENT_METHODS_NAMES {
    CASH = "Cash",
    CARD = "Card / Apple pay",
    PAYPAL = "PayPal",
}

export enum PROVIDERS {
    STRIPE = "stripe",
    PAYTABS = "paytabs",
}

export const THEME_ICONS_PATH = `${process.env.REACT_APP_THEME_URL}/themes/${hostName}/assets`;

export enum ENVIROMENTS {
    DEV = 'dev'
}

export const LOCAL_PERSISTENT_DATA = [
    'categoryOfOptions',
    'orders',
    'menuItemExpand',
    'scrollTop',
    'default_locale',
    'customTip',
    'menuLang',
    'lang',
    'restaurantName',
    'ordersToPay',
    'searchText',
    'imgBaseURL',
    'tableId',
    'restaurantCode',
    'restaurantId',
    'defaultCurrency',
    'categories',
    'currencySymbol',
    'deliveryMinimumAmount',
    'restaurantPermissions',
    'order_pick_up_time',
    'order_comment',
    'set_menu_language',
    'calls',
    'restaurantImage',
    'restaurantInfo',
    'menuItemExpand',
    '_t',
    'paymentTotal',
    'userId',
    'prePaymentOrder'
]