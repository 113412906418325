export enum LOGGER_TYPE {
  GET_SESSION = 'GET_SESSION',
  SESSION_TIMEOUT = 'SESSION_TIMEOUT',

  GET_THEME_LOCALE = 'GET_THEME_LOCALE',
  GET_THEME_LOGO = 'GET_THEME_LOGO',
  GET_THEME_IMAGEPLACEHOLDER = 'GET_THEME_IMAGEPLACEHOLDER',
  GET_THEME_THEMECONFIG = 'GET_THEME_THEMECONFIG',

  JOIN_TABLE = 'JOIN_TABLE',

  ERROR_MODAL = 'ERROR_MODAL',
  PERMISSION_MODAL = 'PERMISSION_MODAL',

  GET_RESTAURANT_INFO = 'GET_RESTAURANT_INFO',
  GET_RESTAURANT_PERMISSIONS = 'GET_RESTAURANT_PERMISSIONS',
  GET_RESTAURANT_DATA = 'GET_RESTAURANT_DATA',
  GET_CATEGORIES = 'GET_CATEGORIES',

  GET_IMAGE = 'GET_IMAGE',

  GET_CATEGORY_OF_OPTIONS = 'GET_CATEGORY_OF_OPTIONS',

  WAITER_ASSISTANCE = 'WAITER_ASSISTANCE',
  CLOSE_SESSION = 'CLOSE_SESSION',

  REFRESH_AUTH = 'REFRESH_AUTH',

  GET_ORDERS_LIST = 'GET_ORDERS_LIST',

  GET_ORDER = 'GET_ORDER',
  SUBMIT_ORDER = 'SUBMIT_ORDER',

  POST_APP_RATING = 'POST_APP_RATING',
  POST_RESTAURANT_RATING = 'POST_RESTAURANT_RATING',

  DEXIE = 'DEXIE',

  MENU_SINGLE_ITEM = 'MENU_SINGLE_ITEM',

  PAYMENT = 'PAYMENT',
  PAYTABS_PAYMENT = 'PAYTABS_PAYMENT',
  PAYPAL_PAYMENT = 'PAYPAL_PAYMENT'
}

export enum LOGGER_ERROR {
  REFRESH_AUTH = 'REFRESH AUTH',

  CHANGE_MENU_LANGUAGE = 'CHANGE_MENU_LANGUAGE'
}