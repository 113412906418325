import React, { Suspense, useEffect } from "react";
import { I18n } from "react-polyglot";
import fetch from "react-native-fetch-polyfill";

import { Loader } from "./Components/Loader/Loader";
import VersionChecker from "./Components/VersionChecker/VersionChecker";

import Routes from "./routes";

import { Elocales } from "./utils/getLocale";
import { credentials } from "./utils/headers";
import { checkAppVersion } from "./utils/getVersion";
import { fetchTimeout } from "./utils/fetchTimeout";

import LocaleContext from "./contexts/locale";
import ModalContext from "./contexts/qrmodal";
import AuthContext from "./contexts/auth";
import VersionContext from "./contexts/version";
import ThemeContext from "./contexts/theme";
import OrderContext from "./contexts/orderContext";
import orderReducer from "./reducers/OrderReducer";

import "./App.css";
import { logError } from "./utils/logger/errorLogger";
import { LOGGER_TYPE } from "./utils/logger/types";
import { ENVIROMENTS } from "./utils/constants";

declare global {
  interface Window {
    dataLayer: object[];
    timeout: number | undefined;
  }
}

const App: React.FC = () => {
  const navigatorLanguage = navigator && navigator.language.slice(0, 2);
  const browserLanguage = (navigatorLanguage && Elocales[navigatorLanguage]) || Elocales.en;
  const [locale, setLocale] = React.useState<string | null>(browserLanguage);
  const [userInfo, setUserInfo] = React.useState<Object | null>({});
  const [isUpdatedVersion, setIsUpdatedVersion] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState<boolean>(false);
  const [themeConfig, setThemeConfig] = React.useState<any>();
  const [themeImageLogo, setThemeImageLogo] = React.useState<any>(null);
  const [themeImagePlaceholder, setThemeImagePlaceholder] = React.useState<any>(null);
  const [loadCounter, setLoadCounter] = React.useState<number>(0);
  const [orderState, dispatch] = React.useReducer<any>(orderReducer,{hasPrePayment: undefined})

  const [messages, setMessages] = React.useState({});
  const [loading, setLoading] = React.useState({});
  const hostName = window.location.hostname;

  async function refreshAuth() {
    return fetch(`${process.env.REACT_APP_API_URL}/user/me?locale=${locale}`, {
      timeout: fetchTimeout,
      method: "GET",
      credentials
    })
      .then(result => result.json())
      .then(data => {
        const { code, userId } = data;
        // Session is valid
        if (!code) {
          // Check first if restaurant and table exists
          if (!localStorage.getItem("restaurantId") || !localStorage.getItem("tableId")) {
            setUserInfo(null);
            return false;
          }

          if (userId) {
            localStorage.setItem("userId", userId);
          }
          setUserInfo(data);
          return true;
        }

        // Session is invalid
        setUserInfo(null);
        return false;
      })
      .catch(() => {
        setUserInfo(null);
        return false;
      });
  }

  async function getVersion() {
    return checkAppVersion().then(result => {
      setIsUpdatedVersion(result.isUpdated);
      return result;
    });
  }

  React.useEffect(
    function getLocale() {
      async function fetchData() {
        try {
          const result = await fetch(`${process.env.REACT_APP_THEME_URL}/themes/${hostName}/${locale}.json`, {
            cache: "no-cache"
          });
          const resultJson = await result.json();
          let parsedData;

          if (typeof resultJson === "string") {
            parsedData = JSON.parse(resultJson);
          } else if (typeof resultJson === "object") {
            parsedData = resultJson;
          }

          setMessages(parsedData);
          const lang = localStorage.getItem("app_language");
          if (lang && locale !== lang) {
            setLocale(lang);
          }

          if (loadCounter !== 0) {
            window.setTimeout(() => {
              setLoading(false);
            }, 750);
          }
          setLoadCounter(prev => prev + 1);
        } catch (e) {
          logError({
            name: LOGGER_TYPE.GET_THEME_LOCALE,
            error: e.toString()
          });
        }
      }
      localStorage.setItem("lang", locale || "en");
      setLoading(true);
      fetchData();
    },
    [hostName, locale]
  );

  React.useEffect(
    function getThemeConfig() {
      async function setTheme() {
        try {
          const result = await fetch(`${process.env.REACT_APP_THEME_URL}/themes/${hostName}/themeconfig.json`);
          const data = await result.json();
          let parsedData;

          if (typeof data === "string") {
            parsedData = JSON.parse(data);
          } else if (typeof data === "object") {
            parsedData = data;
          }

          setThemeConfig(parsedData);
        } catch (e) {
          logError({
            name: LOGGER_TYPE.GET_THEME_THEMECONFIG,
            error: e.toString()
          });
        }
      }

      setTheme();
    },
    [hostName]
  );

  React.useEffect(
    function getImages() {
      async function imageExists(image_url) {
        var http = new XMLHttpRequest();

        http.open("HEAD", image_url, false);
        http.send();

        return http.status !== 404;
      }
      async function blobToBase64(blob) {
        const reader: any = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      }
      async function setThemeLogo() {
        try {
          const imageUrl = `${process.env.REACT_APP_THEME_URL}/themes/${hostName}/logo.png`;
          if (await imageExists(imageUrl)) {
            const result = await fetch(imageUrl);
            const image = await result.blob();
            const image64 = await blobToBase64(image);
            if (image64) {
              setThemeImageLogo(image64);
            }
          }
        } catch (e) {
          logError({
            name: LOGGER_TYPE.GET_THEME_LOGO,
            error: e.toString()
          });
        } finally {
          window.setTimeout(() => {
            setLoading(false);
          }, 750);
        }
      }

      async function setThemePlaceholder() {
        try {
          const imageUrl = `${process.env.REACT_APP_THEME_URL}/themes/${hostName}/placeholder.png`;
          if (await imageExists(imageUrl)) {
            const result = await fetch(imageUrl);
            const image = await result.blob();
            const image64 = await blobToBase64(image);
            if (image64) {
              setThemeImagePlaceholder(image64);
            }
          }
        } catch (e) {
          logError({
            name: LOGGER_TYPE.GET_THEME_LOGO,
            error: e.toString()
          });
        } finally {
          window.setTimeout(() => {
            setLoading(false);
          }, 750);
        }
      }

      setThemeLogo();
      setThemePlaceholder();
    },
    [hostName]
  );

  return (
    <ThemeContext.Provider value={{ themeConfig, themeImageLogo, themeImagePlaceholder }}>
      <VersionContext.Provider value={{ isUpdatedVersion, getVersion }}>
        <AuthContext.Provider value={{ userInfo, refreshAuth }}>
          <ModalContext.Provider value={{ open, setOpen }}>
            <LocaleContext.Provider value={{ locale, setLocale }}>
              <OrderContext.Provider value={{ orderState, dispatch }} >
              <I18n locale={locale} messages={messages}>
                <div className="App">
                  <Suspense fallback={<Loader />}>
                    {(process.env.REACT_APP_ENV !== ENVIROMENTS.DEV) && <VersionChecker /> }
                    {loading ? <Loader /> : <Routes />}
                  </Suspense>
                </div>
              </I18n>
              </OrderContext.Provider>
            </LocaleContext.Provider>
          </ModalContext.Provider>
        </AuthContext.Provider>
      </VersionContext.Provider>
    </ThemeContext.Provider>
  );
};

export default App;
